
.Select-location-btn table tr th {
  height: 50px;
  /* background-color: rgb(213, 214, 215); */
  border: 2px solid white;
  width: calc(100% - 100px);
  border-radius: 5px;
}
.Select-location-btn table td {
  border: 2px solid white;
  height: 75px;
  background-color: rgb(239, 241, 244);
  border-radius: 5px;
}
.Skaleton-table{
  width: 100%;
}
.Skeleton-headings{
  width:100%
}

