.QuickPayModal .modal-content{
    padding: 0 !important;

}
.QuickPayModalHeader{
    background-color: #70BBFD;
    display: flex;
    align-items: center;
}
.QuickPayModalHeader span{
   color: #fff;
   font-size: 20px;
}
.QuickPayModalHeader .close{
   color: #fff;
}

.cancel-btn {
    background-color: #ccc;
    color: white;
}

.report-container {
    display: flex;
}

.report-container-rhs {
    padding-left: 25px;
    justify-content: center;
}


.report-container>div {
    display: flex;
    flex-direction: column;
    width: 40%;
}


.report-lhs-text {
    font-size: 15px;
    font-weight: bold;
    width: 35%;
}

.report-rhs-text {
    font-size: 15px;
}
