.transaction-log-data{
display: flex;
flex-direction: row;
width: 100%;
}
.transaction-log-data-lhs{
   padding: 10px;
   width: 50%;
}
.transaction-log-data-rhs{
    width: 50%;
    padding: 10px;
}