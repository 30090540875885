.right-space .panding{
    width: 100px;
    border-radius: 20px;
    background-color: #FF9292;
    color: #fff;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.right-space .completed{
    width: 100px;
    border-radius: 20px;
    background-color: #26DD7D;
    color: #fff;
    font-style: italic;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}