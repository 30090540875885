@media print {
    #divToPrint {
        display: flex;
        justify-content: center;
        text-align: center;
    }
}

.para {
    background-color: red;
}

.download-user-datails {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
}

.download-booking-id {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: flex-start;
}

.provide-name {
    font-size: 17px;
    color: #4AB9FA;
}

.border-id-line {
    width: 100%;
    height: 2px;
    background-color: #4AB9FA;
    border: 0;
    margin: 10px 0;
}

.download-user-datails-lhs {
    margin-top: 10px;
}

.download-user-datails-lhs {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.download-trans-total-details {
    margin-top: 19px;
}

.download-trans-data-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    margin-bottom: 25px;
}

.trans-data-inner-rhs {
    width: 84%;
    display: flex;
    justify-content: flex-end;
}

.download-pay-result {
    font-size: 14px;
    font-weight: bold;
    width: 100px;
    display: flex;
    justify-content: flex-end;
}

.download-trans-data-rhs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 15px;
}

.download-btn {
    background-color: transparent;
    width: 120px;
    padding: 5px 0;
    border: 1px solid #4AB9FA;
    color: #4AB9FA;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-right: 40px;
}

.download-trans-data-rhs>.trans-data-inner-rhs>label {
    margin-bottom: 0;
}

.invoice-btn {
    margin-top: -10px;
}

.PhoneInputCountryIconImg {
    height: 18px;
    width: 32px;
    margin-left: 5px;
}
.PhoneInputCountryIcon--border{
    box-shadow: none;
    border: 0;
}

.PhoneInput {
    width: 300px;
}

.PhoneInputInput {
    margin-left: 10px;
}

.PhoneInputCountrySelectArrow {
    margin-left: 20px;
}

.cancel {
    color: rgb(31, 182, 241);
    background: none !important;
    border: none;
    font-weight: 600;
    margin-right: -6px;
}