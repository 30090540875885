

.table-bordered {
    border:0;
  }

 .custom-select{
     margin-right:5px;
 } 

 .table-bordered th, .table-bordered td {
    border:0;
    font-size: 15px;
    padding: 20px 10px;
    border-bottom: 1px solid #ccc;
} 
.table-bordered{
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    border-collapse: inherit;
}
.table-striped {
    background: none;
}
.dataTables_length label{
    font-size: 15px;
}
.dataTables_info{
    font-size: 15px;
}
 
 