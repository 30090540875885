
   
.view-btn{
    background-color: #4AB9FA;
    color: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 5px 10px;
    border-radius: 3px;
}
.modal-view-btn{
    display: flex;
    align-items:center;
    justify-content:space-around;
    color: #70BBFD !important;
    width: 45px;
    font-size:15px;
    font-weight:400;
    border: 1px solid #70BBFD;
    background-color: #fff;
    cursor:pointer;
    border-radius:4px ;
    padding: 5px;
    margin-top:5px
}
.modal-download-btn{
    display: flex;
    align-items:center;
    justify-content:space-around;
    color: #42ebc0;
    font-size:15px;
    width: 45px;
    font-weight:400;
    border: 1px solid #42ebc0;
    background-color: #fff;
    cursor:pointer;
    border-radius:4px ;
    padding: 6px;
    margin-left: 10px;
}
.modal-edit-btn{
    display: flex;
    align-items:center;
    justify-content:space-around;
    color: #009c46;
    font-size:20px;
    font-weight:400;
    border: 1px solid #009c46;
    background-color: #fff;
    cursor:pointer;
    border-radius:4px ;
    padding: 5px;
    width: 45px;
}
.modal-delete-btn{
    display: flex;
    align-items:center;
    justify-content:space-around;
    color: #da3300;
    font-weight:400;
    background-color: #fff;
    border: 1px solid #da3300;
    cursor:pointer;
    border-radius:4px ;
    padding: 5px;
    width: 45px;
}
.modal-manage-btn{
    display: flex;
    align-items:center;
    justify-content:space-around;
    color: #00f0bc;
    background-color: #fff;
    font-weight:400;
    border: 1px solid #00f0bc;
    cursor:pointer;
    border-radius:4px ;
    padding: 5px;
    width: 45px;
}