.select__option{
    border: 1px solid #f2f2f2;
    color: #646777;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    outline: orange;
    width: 100%;
    border-radius: 5px;
    font-family: sans-serif;
    padding-left: 7px;
}
.redirectRadio{
    height: auto !important;
}
.titleHeadingOfNewsDevide{
    margin-top: 20px;
    font-style: italic;
    font-weight: bold;
}
.news-image{
    display: flex;
    flex-direction: column;
}