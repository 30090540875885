@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,200;0,300;0,500;1,400&family=Poppins:wght@100;200;300;400;500;600&display=swap');
:root {
    --alumni-family: 'Alumni Sans', sans-serif;
    --poppins-family: 'Poppins', sans-serif;
}

.calender-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    margin-top: 120px;
    margin-top: 0;
    position: relative;
    width: 100%;
}
.download-excel-btn{
    background-color:#fdae00;
    border-radius: 4px;
    padding: 7px 10px;
    width: 100%;
    text-align: center;
    color: #fff;
    cursor: pointer;
    display: flex;
}
.download-excel-cancel-btn{
    background-color:#b9b0b0;
    border-radius: 4px;
    padding: 7px 10px;
    width: 100%;
    text-align: center;
    color: #fff;
    cursor: pointer;
    display: flex;
}
.Excel-card{
    display: flex;
    justify-content: space-between;
}
.Excel-card-div{
   display: flex;
   gap: 15px;

}
.inner-calender-booking{
    width: 97%;
    padding: 50px 0;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
}

.inner-calender-booking .inner-lhs {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.react-calendar {
    padding: 20px !important;
    font-size: 17px !important;
    width: 400px !important;
    border-radius: 2px !important;
    border: 1px solid #ffffff !important; 
    background-color: #f9f9f9!important;
}
.react-calendar__tile:disabled {
    background-color: transparent !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #bebebe !important;
}

.react-calendar__tile:disabled {
    border-radius: 50%;
    color: #ccc;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    color: #5c5c5c;
    font-family: var(--poppins-family);
}

abbr[title] {
    border-bottom: none !important;
    text-decoration: none !important;
    color: #5a5a5a;
    font-family: var(--poppins-family);
}
.react-calendar__navigation button[disabled] {
    border-radius: 10px;
    background-color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 33px;
    margin: 4px 3px;
    border: 1px solid #e3e3e3;
    cursor: pointer;
}
.react-calendar__navigation__label__labelText .react-calendar__navigation__label__labelText--from{
    font-size: 14px;
    font-weight: bold !important;
}
.react-calendar__navigation button {
    min-width: 35px !important;
    border-radius: 10px;
    background-color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 33px;
    margin: 4px 3px !important;
    border: 1px solid #e3e3e3 !important;
    cursor: pointer;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #fff;
}
.react-calendar__navigation__arrow .react-calendar__navigation__next-button{
    border-radius: 10px;
    background-color: #fff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 33px;
    margin: 4px 3px;
    border: 1px solid #e3e3e3;
}
element.style {
    flex-basis: 14.2857%;
    max-width: 14.2857%;
    overflow: hidden;
}
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}
[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    line-height: 2.55em !important; 
}
.react-calendar,
.react-calendar *,
.react-calendar :after,
.react-calendar :before {
    color: #5c5c5c;
    font-family: Verdana, sans-serif;
}
.react-calendar__tile--hasActive {
    background: #60a3e6;
    border-radius: 5px !important;
}

.react-calendar__tile--now {
    background: #FDAE00!important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #FDAE00 !important;
    border-radius: 50% !important;
    color: #fff !important;
    line-height: 20px !important;
}
.square-img {
    width: 150px !important;
    height: 150px !important;
    border: 3px solid #FDAE00 !important;
}
.react-calendar__tile:enabled:hover {
    border-radius: 50% !important;
    background: #bebebe !important;
}
.react-calendar__tile:enabled:focus {
    border-radius: 50%;
    background: #FDAE00 !important;
}

.react-calendar__year-view .react-calendar__tile:enabled:hover{
    border-radius: 5px !important;
}
.react-calendar__year-view .react-calendar__tile:enabled:focus{
    border-radius: 5px !important;
    background: #FDAE00 !important;
}
.react-calendar__year-view .react-calendar__tile--now{
    border-radius: 5px !important;
}

.react-calendar__decade-view__years .react-calendar__tile:enabled:hover{
    border-radius: 5px !important;
}
.react-calendar__decade-view__years .react-calendar__tile:enabled:focus{
    border-radius: 5px !important;
    background: #FDAE00 !important;
}
.react-calendar__decade-view__years .react-calendar__tile--now{
    border-radius: 5px !important;
}

.react-calendar__tile--active {
    background: #fdae00;
    border-radius: 50%;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    color: #5c5c5c;
    font-family: var(--poppins-family);
    font-size: 13px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    border-radius: 10px;
}

.inner-calender-booking h2 {
    text-align: left;
    font-size: 11px;
    font-family: var(--poppins-family);
    margin-bottom: 0px;
}
.cart-container h2 {
    text-align: left;
    font-size: 14px;
    font-family: var(--poppins-family);
    margin-bottom: 0px;
}
.sel-and-time{
 display: flex;
 flex-direction: row;
 align-items: flex-start;
 justify-content: space-between;
 width: 90%;

}
.timeSlot {
   width: 100%;
    border-radius: 2px;
    height: 35px;
    font-family: var(--poppins-family);
    border: 1px solid #f1f1f1;
    outline: none;
}

.timeSlot:focus {
    border: 1px solid #FDAE00;
}

.timeSlot option {
    font-family: var(--poppins-family);
    font-size: 14px;
    outline-style: none;
}
.select-time {
    width: 65%;
}
.select-inc-dec-container{
    width: 35%;
}
.select-inc-dec {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10px;

}
.select-inc-dec h2 {
    font-family: var(--poppins-family);
}
.select-inc-dec small {
    font-family: var(--poppins-family);
    margin: 0;
    font-size: 13px;
    padding: 0px 10px;
}
.time-inc-dec{
     font-size: 15px;
     height: 35px;
     display:flex;
     flex-direction:row;
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.inner-calender-booking .inner-rhs {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}
.inner-calender-booking .inner-rhs h3 {
    font-family: var(--poppins-family);
    color: #1e1e1e;
    font-weight: 400;
    font-size: 15px;
}

.select-inc-dec .faMinus {
    color: rgb(233, 233, 233);
    position: absolute;
    left: -1px;
    top: -1px;
    box-shadow: 0 1px 3px #3a3a3a;
    border-radius: 50%;
    cursor: pointer;
}

.select-inc-dec .faPlus {
    color: rgb(233, 233, 233);
    position: absolute;
    left: -1px;
    top: -1px;
    box-shadow: 0 1px 3px #3a3a3a;
    border-radius: 50%;
    cursor: pointer;
}
.bg-fa-minuts{
    background-color: black;
    width: 13px;
    height: 13px;
    position: relative;
    border-radius: 50%;
}
.cart-container{
    width: 90%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top:25px;
}
.cart {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 20px;
}

.clild-cart {
    width: 128px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-content: center;
    align-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 7px;
    margin: 5px;
    cursor: pointer;
    height: 75px;
}


.clild-cart .number {
    font-family: var(--poppins-family);
    font-weight: 400;
    color: #707070;
}

.clild-cart .doors {
    font-family: var(--poppins-family);
    font-weight: 400;
    color: #707070;
}

.clild-cart .doors {
    font-family: var(--poppins-family);
    font-weight: 500;
    color: #707070;
}

.clild-cart .ladies-only {
    font-family: var(--poppins-family);
    font-weight: 500;
    color: #ff0000;
}

.court-submit-btns {
    width: 97%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border:1px solis red;
    margin: 25px 0;

}
 .court-submit-btns .add {
    background-color: #FDAE00;
    color: white;
    border: 0;
    margin-right: 15px;
    padding: 10px 20px;
    border-radius: 2px;
    font-family: var(--poppins-family);
    text-decoration: none;
    font-weight: 600;
}

 .court-submit-btns .cancel {
    background-color: rgb(226, 226, 226);
    color: black;
    border: 0;
    padding: 10px 25px;
    border-radius: 2px;
    font-family: var(--poppins-family);
    text-decoration: none;
    font-weight: 600;
}

.court-submit-btns .add:disabled {
    background-color: rgb(226, 226, 226);
}

@media(max-width:1199px){
    .select-time {
        width: 75%;
    }
    .inner-calender-booking {
        width: 80%;
    }
    .court-submit-btns {
        width: 80%;
    }
}
@media(max-width:1024px){
    .select-time {
        width: 100%;
    }
}
@media(max-width:991px){
    
    .sel-and-time {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
    .inner-calender-booking .inner-lhs {
        width: 100%;
        align-items: center;
    }
    .inner-calender-booking .inner-rhs {
        width: 390px;
    }
    .inner-calender-booking {
        width: 96%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cart {
        width: 390px;
        margin-top: 20px;
    }
  
    .inner-calender-booking .submit-btns {
        width: 390px;
    }
    .court-submit-btns {
        width: 100%;
        justify-content: center;
    }
    .inner-calender-booking h2 {
        margin-top: 15px;
        display: flex;
        justify-content: center;
    }
}
@media(max-width:425px){
    .react-calendar {
        max-width: 290px !important;
        padding: 10px !important;
    }
    .react-calendar__tile {
        padding: 0.8em 0.5em !important;
    }
    .react-calendar, .react-calendar *, .react-calendar *:before, .react-calendar *:after {
        font-size: 12px;
    }
    .select-time {
        width: 290px;
    }
    .inner-calender-booking .inner-rhs h3 {
        font-family: var(--poppins-family);
        color: #1e1e1e;
        font-weight: 400;
        font-size: 15px;
    }
    .inner-calender-booking .inner-rhs h3 {
        font-size: 15px;
    }
    .cart {
        width: 290px;
    }
    .inner-calender-booking .submit-btns {
        width: 290px;
    }
    .inner-calender-booking .inner-rhs {
        width: 320px;
    }
}

