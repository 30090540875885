.Booking-details-edit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.Booking-details-inner {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    width: 100%;
}

.client-text {
    width: 200px;
}

.input-div {
    height: 35px;
    border: 1px solid #ccc;
    border-radius: 3px;
    min-width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.current-timing {
    min-width: 400px;
}

.input-div>input {
    width: 90%;
    border: 0;
    outline: none;
    padding-left: 6px;
}

.input-div>.search-icon {
    margin-right: 5px;
    height: 22px;
    width: 22px;
    color: rgb(170, 170, 170);
}

.input1-div {
    height: 35px;
    border: 1px solid #ccc;
    border-radius: 3px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input1-div>input {
    width: 90%;
    border: 0;
    outline: none;
    padding-left: 6px;
}

.input1-div>.search-icon {
    margin-right: 5px;
    height: 22px;
    width: 22px;
    color: rgb(170, 170, 170);
}

.Require-star {
    color: red;
    margin-left: 5px;
}

.client-date {
    display: flex;
    align-items: flex-end;
    width: 400px;
    justify-content: space-between;
}

.client-date-time {
    color: #70BBFD;
    text-decoration: underline;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.Booking-details-save {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.save-input {
    background-color: #fc8a28;
    padding: 7px 15px;
    border: 1px solid #fc8a28;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    width: 90px;
    height: 35px;

}

.save-input:active {
    background-color: #fc8a28;
    padding: 7px 15px;
    border: 1px solid #fc8a28;
    color: #ffffff;
    border-radius: 4px;
    cursor: not-allowed;
    font-size: 13px;
    width: 90px;
    height: 35px;
}

.save-input:disabled {
    background-color: #c0c0c0;
    border: 1px solid #c0c0c0;
    color: #ffffff;
    cursor: not-allowed;
}

.booking-user-Edit-desabled:disabled {
    background-color: transparent;
    color: #AAAAAA;
}

.Booking-details-form {
    width: 100%;
}

.client-date-time>.react-datepicker-wrapper>.react-datepicker__input-container>input {
    border: 0;
    outline: none;
    color: #70BBFD;
    text-decoration: underline;
    width: 80px;

}

.react-datepicker-wrapper {
    display: flex !important;
    align-items: center;
}

.Select-control {
    border: 0;
    outline: none;
    color: #70BBFD;
    padding-bottom: 1px;
}

.react-datepicker-wrapper {
    width: auto !important;
}

.date-picker-time {
    font-size: 14px;
}

.inner-current-time {
    min-width: 340px;
    display: flex;
    justify-content: space-between;
    padding: 2px 10px;
}

.save-btn {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}

.prev-comments {
    display: flex;
    flex-direction: column;
}

.detail-modal-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
}

.inner-modal-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.detail-modal-container .container-lhs {
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.detail-modal-container .container-rhs {
    width: 65%;
    padding: 10px;
}

.booking-id {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.court-name {
    width: 100%;
    font-size: 19px;
    font-family: sans-serif;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
}

.court-name>h5 {
    font-size: 18px;
    color: white;
    font-weight: 500;
}

.payment-details {
    display: flex;
}

.payment-details-text {
    font-size: 15px;
    width: 37%;
}

.invoice-text,
.due-text {
    font-size: 15px;
    width: 27%;
}

.payment-details-result {
    font-size: 15px;
    font-weight: bold;
}

.user-edit-icon {
    font-size: 18px;
    color: #ffffff;
    margin-left: 10px;
}

.comment-container {
    padding: 10px;
}

.editFile-comments-icon {
    width: 23px;
    height: 23px;
    color: #1bb4f0;
}

.comment-container-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 10px;

}

.Modal-comment-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.Modal-comment-cancel-btn {
    border: 0;
    outline: none;
    background-color: transparent;
    color: #1bb4f0;
    padding: 8px 15px;
    border-radius: 4px;
    font-size: 15px;
    border: 0;
    margin-right: 10px;
}

.Modal-comment-save-btn {
    background-color: #06ADEF;
    color: #ffffff;
    padding: 7px 10px;
    border-radius: 4px;
    font-size: 15px;
    border: 0;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal-comment-save-btn:hover {
    background-color: #67d4ff;
}

.Modal-comment-save-btn:disabled {
    background-color: #ccc;

}

.Modal-comment-cancel-btn:hover {
    background-color: rgb(218, 235, 255);
}

.Modal-comment-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.user-all-comments {
    border: 1px solid red;
    margin-top: 20px;
}

.inner-Modal-comment-container {
    width: 90%;
    padding: 20px 0;
}

.comment-area {
    width: 100%;
    height: 70px;
    padding: 10px;
}

.edited-user {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.user-comments {
    display: flex;
    flex-direction: row;
}

.edited-user>span {
    font-size: 13px;
    color: #434343;
}

.edit-middle-line {
    width: 1px;
    height: 25px;
    background-color: #ccc;
    margin-top: -10px;
    margin-left: 10px;
}

.pack-type {
    margin: 10px;
    font-family: sans-serif;
}

.show-all-edits {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
}

.show-all-text {
    color: #1bb4f0;
    margin-left: 2px;
    font-family: sans-serif;
    margin-right: 15px;
    font-size: 14px;
    cursor: pointer;
}

.pencil-icon,
.eye-icon {
    color: #1bb4f0;
    margin-right: 5px;
    font-size: 18px;
    text-align: center;
}

.show-all-text:hover {
    text-decoration: underline;
    color: #00a0df;
}

.buttons-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.buttons-containerr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.btn-close {
    background-color: transparent;
    color: #1bb4f0;
    padding: 8px 15px;
    border-radius: 4px;
    font-size: 15px;
    border: 0;
    margin-right: 10px;
}

.btn-close-booking {
    background-color: transparent;
    color: #1bb4f0;
    padding: 8px 15px;
    border-radius: 4px;
    font-size: 15px;
    border: 0;
    margin-right: 10px;
}

.btn-cancel-booking {
    background-color: #ff9292;
    color: #ffffff;
    padding: 7px 10px;
    border-radius: 4px;
    font-size: 15px;
    border: 0;
}

.btn-cancel-booking:hover {
    background-color: #ffb7b7;
}

.btn-close:hover {
    background-color: rgb(218, 235, 255);
}

.btn-close-booking:hover {
    background-color: rgb(218, 235, 255);
}

.btn-edit {
    background-color: #1bb4f0;
    color: #ffffff;
    padding: 8px 15px;
    border-radius: 4px;
    font-size: 15px;
    border: 0;
    margin-left: 10px;
}

.btn-edit:hover {
    background-color: #7edbff;
}

.show-hide-container {
    margin-top: 15px;
    transition: width 2s linear 1s;

}

.commentData {
    background-color: #f8f8f8;
    margin: 10px 0;
    padding: 10px;
    display: flex;
    align-items: center;
    transition: 2s linear 1s;
    justify-content: space-between
}

.commentData>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.commentData-rhs {
    font-size: 10px;
    color: #8a8a8a;
}

.comment-Icon {
    color: #ccc;
    font-size: 30px;
    margin-right: 15px;
}

/* -----------------------tab 2 ------------------------- */

.table-user-details>tr>td {
    width: 50%;
    padding-left: 10px;
    border: 1px solid rgb(233, 233, 233);
    color: #4b4b4b;
    font-size: 13px;
}

.client-info {
    font-size: 18px;
    margin-bottom: 10px;
    font-family: sans-serif;
}

.client-icon-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.client-icon-name>h5 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.client-icon {
    font-size: 16px;
    margin-right: 8px;
}

.client-email,
.client-ph-no {
    color: #06ADEF;
}

.client-email:hover,
.client-ph-no:hover {
    color: #0080b3;
    text-decoration: underline;
}

.client-edit-btn {
    background-color: #06ADEF;
    color: #ffffff;
    padding: 7px 10px;
    border-radius: 4px;
    font-size: 15px;
    border: 0;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.client-edit-icon {
    margin-right: 10px;
    font-size: 17px;
}



/* -----------------------tab 3 ------------------------- */

.edit-title {
    font-size: 18px;
    padding-left: 20px;
}



.space-feild {
    border: 1px solid #ccc;
    height: 32px;
    border-radius: 4px;
}

.width-feild {
    border: 1px solid #ccc;
    height: 32px;
    border-radius: 4px;
}

.left-space {
    margin-left: 30px;
}

.form-buttons {
    margin-top: 15px;
    display: flex;
}

.submit-btn {
    background-color: #FFD943;
    border: 0;
    padding: 8px 15px;
    color: white;
    font-size: 15px;
    font-family: sans-serif;
    border-radius: 4px;
    margin-left: 10px;
}

.submit-btn:hover {
    background-color: #ffea94;
}

.reset-btn {
    border: 0;
    padding: 8px 15px;
    color: white;
    font-size: 15px;
    font-family: sans-serif;
    border-radius: 4px;
}

.reset-btn:hover {
    background-color: #b1b1b1;
}

.error-text {
    color: rgb(174, 0, 0);
}

.court-name-edit {
    display: flex;
    padding-top: 10px;

}

.user-icon {
    font-size: 18px;
    margin-right: 10px;
    color: #ffffff;
}

.money-icon-container {
    display: flex;
    margin: 15px;
    padding-top: 15px;
}

.money-icon {
    margin-right: 10px;
    font-size: 18px;
    color: #2c3547;
}

.court-name-text,
.money-name-text {
    font-family: sans-serif;
    color: #ffffff;
    font-size: 17px;
}

/* ---------------------------tab -5 ------------------------------- */
.payments-tatus-container {
    display: flex;
    width: 100%;
}

.payments-tatus-container>div {
    width: 50%;
}


.add-client-label {
    min-width: 150px;
}

.add-client-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 13px;
    color: #141411;
    outline: none;
    height: 32px;
    min-width: 300px;
}

.add-client-phone {
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    height: 32px;
    min-width: 300px;
}

.add-client-phone .PhoneInputCountryIcon--border {
    background-color: transparent;
    box-shadow: none;
    height: auto;
    outline: none;
}

.add-client-phone .PhoneInputInput {
    border: 0;
    outline: none;
    padding-left: 5px;
}

.border-2 {
    border-width: 2px !important;
}

.border-lightblue {
    border-bottom: #33ffff;
}

.marginleft{
    margin-left: -80px;
}

.Eligible{
    color: red;
    /* font-size: 10px; */
}

.edit{
    font-weight: 20px;
    text-decoration: underline;
}

.height{
    height: 30px;
}

.userdetails{
    font-size: 18px;
}

.search-icon{
    height: 8;
    width: 5;
}

.quantity input {
    width: 60px;
    height: 30px;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    padding-left: 20px;
    border: none;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
    font-size: 1rem;
    border-radius: 4px;
  }
  
  .quantity input:focus {
    outline: 0;
  }
  
  .quantity-nav {
    float: left;
    position: relative;
    height: 42px;
  }
  
  .quantity-button {
    position: relative;
    cursor: pointer;
    border: none;
    border-left: 1px solid rgba(0, 0, 0, 0.08);
    width: 30px;
    height: 30px;
    text-align: center;
    color: #333;
    font-size: 13px;
    font-family: "FontAwesome" !important;
    line-height: 1.5;
    padding: 0;
    background: #FAFAFA;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .quantity-button:active {
    background: #EAEAEA;
  }
  
  .quantity-button.quantity-up {
    position: absolute;
    height: 50%;
    top: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    font-family: "FontAwesome";
    border-radius: 0 4px 0 0;
    line-height: 1.6
  }
  
  .quantity-button.quantity-down {
    position: absolute;
    bottom: 0;
    height: 50%;
    font-family: "FontAwesome";
    border-radius: 0 0 4px 0;
  }
  .innerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 9px;
  }
  .username {
    font-size: 16px;
    text-transform: capitalize;
    font-weight: '600';
    line-height: 19px;
    color: #000000;
  }
  .nationalty{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .details {
    color:#4B4B4B;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    vertical-align: middle;
  }
  .pricedetails {
    font-size: 12px;
    font-weight: 700;
    color: #000000;
  }