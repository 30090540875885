.card-body {
    padding: 20px 20px;
}
.role-list-body{
    padding: 32px 32px;
}

.create-card-body{
    padding: 32px 32px;
}

.form__form-group-label {
    font-family: 'Trebuchet MS', sans-serif;
    margin-top: 20px;
    font-size: 13px;
    color:#141414 ;
  
}
.links-container{
    display: flex;
    align-items: center;
    gap: 10px;
}
.links-container>input{
    width: auto;

}
.form__form-group-field>.form__form-group-input-wrap>input {
    border: 1px solid #ccc !important;
    border-radius: 4px;
}

.css-yk16xz-control {
    min-height: 32px !important;
    border: 0;
}

.css-tlfecz-indicatorContainer {
    padding: 0 8px !important;
}

.css-g1d714-ValueContainer {
    padding: 0 8px!important;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.textareaFeild {
    height: 32px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    outline: none;
    color: #393b3c;
}
.form__form-group-field input {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 13px;
    color: #393b3c;
}

.css-b8ldur-Input {
    margin: 0 !important;
    padding: 0 !important;
}

.left-space {
    margin-left: 30px;
}

.btn-cust-css {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
}

.modal-content {
    border-radius: 5px;
}

.send-mail {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: auto !important;
    top: 14px;
    position: relative;
    left: -12px;
}
.bulk-header-container{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bulk-header{
     display: flex;
     justify-content: space-between;
     width: 100%;
     position: relative;
}

.bulk-h3{
      width: 12%;
      text-align: "center";
      font-size: 14px;
      font-weight: 500;
}

.bulk-d{
    border:1px solid #cccccc;
    height: 35px;
    min-width: 62%;
    border-radius: 5px;
    padding: 15;
    font-size: 18;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bulk-input{
    margin-left: 10px;
    border:none;
    width:92%;
    padding: 5px 10px;
    font-size: 14px;
    background-color: transparent;
    resize: none;
    outline: none;
    z-index: 0;
    position: relative;
}

.bulk-button{
    height: 35px;
    font-size: 15px;
    border-radius:5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    margin-left: 20px;
}

.email-list{
    position: absolute;
    top: 35px;
    max-height: 400px;
    z-index: 100;
    background-color: #fff;
    overflow-y: scroll;
    border: 1px solid #cccccc;
    padding: 4px;
    border-radius:4px;
    width: 62%;
}
.email-list::-webkit-scrollbar {
    width: 10px;
}
.email-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
.email-list::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 4px;
  }
.email-list:hover{
    cursor: pointer;
}
.single-emeil__data{
   padding:5px 10px ;
   box-shadow: 0 5px 4px -6px #ccc;
   font-family: sans-serif;
   font-weight: 500;
   font-size: 14px;
   letter-spacing: 0.1px;

}
.close{
    font-size: 24px;
    margin-right: 5px;
    cursor: pointer;
}
.hide-content{
    visibility: hidden;
}
.user-packages-body{
padding:20px;
}
.flex{
    margin-right: 10px;
    /* border: 2px solid black; */
    border-radius: 50px;
    width: 35px;
    height: 35px;
    overflow: hidden;
    background-position: center;
    object-fit: cover;
}