.calender__available {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #4ce1b6 !important;
    border: 1px solid #fff !important;
    color: #343a40 !important;
    text-align: center;
    width: 100px;
    flex-grow: 1;
    padding: 0px !important;
    font-size: 16px;
    font-family: sans-serif;
    border-radius: 4px;
    cursor: pointer;
}

.calender__booked {
    border: 0.5px solid rgb(255, 255, 255) !important;
    color: rgb(248, 249, 250) !important;
    text-align: center;
    width: 100px;
    flex-grow: 1;
    font-size: 16x;
    font-family: sans-serif;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-content: space-around;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    text-transform: capitalize;
    padding: 10px !important;
}

.calender__booked_changes {
    border: 0.5px solid rgb(255, 255, 255) !important;
    text-align: center;
    width: 100px;
    flex-grow: 1;
    font-size: 16x;
    font-family: sans-serif;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-content: space-around;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: nowrap;
    text-transform: capitalize;
    padding: 10px !important;
}

.calender__timeslots {
    text-align: center;
    margin: 1px;
    background-color: rgb(248, 248, 248);
    padding-right: 9px;
    border-radius: 4px;
    width: 14.1%;
    min-width: 150px;
}

.calender__courts {
    text-align: center;
    flex-grow: 1;
    width: 100px;
    background-color: rgb(248, 248, 248) !important;
    border-radius: 4px;
    margin: 1px;
}

.calendar tr td[style*=border] {
    margin: 1px;
}



.table-responsive::-webkit-scrollbar {
    width: 5px;
    height: 6px;
}

.table-responsive::-webkit-scrollbar-track {
    border-radius: 5px;
}

.table-responsive::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgb(170, 169, 169);
    background-color: '#fff';
}

.table-responsive::-webkit-scrollbar:vertical {
    display: none;
}