.submit-container {
    margin-bottom: 10px;
}

.select-location-text {
    display: flex;
    justify-content: flex-start;
}

.select-location {
    width: 220px;
    font-size: 14px;
    color: #f2f4f7;
    border: 1px solid #f2f4f7 !important;
}


.select-location option {
    font-size: 14px;
    color: #6d6d6d;
}

.top-container .page-title {
    margin-top: 4px;
    font-size: 16px;
    color: #6d6d6d;
    font-family: sans-serif;
    margin-right: 15px;
}

.default-title {
    margin-top: 4px;
    font-size: 16px;
    color: #6d6d6d;
    font-family: sans-serif;
    margin-right: 15px;
}

.top-container .custom-css-dp .react-date-picker__wrapper {
    border: 1px solid #9c9c9c;
    color: #aeaeae;
    outline: none;
    width: 220px;
    height: 32px;
    padding-left: 4px;
}

.react-date-picker__inputGroup__input {
    outline: none;
}

.react-date-picker--open {
    outline: none;
}

.date-submit {
    height: 35px;
    background-color: #FDAE00;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    border-radius: 4px;
    font-size: 15px;
    font-family: sans-serif;
    cursor: pointer;
    outline: none;
    border: 0;
}
.date-submit-disable {
    height: 35px;
    background-color: #d6d6d6;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    border-radius: 4px;
    font-size: 15px;
    font-family: sans-serif;
    cursor: not-allowed;
    outline: none;
    border: 0;
}

.submit-time-loc {
    display: flex;
    justify-content: flex-end;
}

.calender-loader {
    color: #4ce1b6;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border;
}
.calendar thead tr th:first-child{
    flex-grow: 0 !important;
    width: 14.1% !important;
    min-width: 150px;
}
.cal-table__th{
    text-align: "center";
    flex-grow: 1;
    width: 100px;
    background-color: '#f8f8f8';
    border-radius: 4px;
    margin: 1px ;
}
.top-container {
    width: 100%;
    background-color: #fff;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.top-container .inner-container {
    width: 400px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.submit-btn-container {
    display: flex;
    flex-direction: row;
    width: 20%;
    align-items: flex-end;
    justify-content: center;
}

.submit-btn-container>div {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}

.submit-btn {
    display: flex;
    justify-content: flex-end;
}

.category {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    border-radius: 20px;
    font-size: 12px;
    text-transform: capitalize;
}

.category[data-status="Events"] {
    background-color: rgb(255, 190, 210);
}

.category[data-status=""] {
    background-color: rgb(224, 189, 129);
}

.category[data-status="Tournaments"] {
    background-color: rgb(235, 116, 189);
}

.category[data-status="Maintainence"] {
    background-color: rgb(128, 72, 72);
}

.category[data-status="Coaching"] {
    background-color: rgb(180, 194, 230);
}

.category[data-status="Others"] {
    background-color: rgb(38, 200, 221);
}

.rounded-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 5px;
}

.div-round {
    height: 15px;
    width: 15px;
    border-radius: 50%;
}

.mr-10 {
    margin-right: 10px;
}


.AvailableSlot {
    background-color: #4ce1b6 !important;
    border: 0.5px solid #fff;
    color: "#343a40";
    text-align: center;
    width: 100px;
    flex-grow: 1;
    padding: 10px;
    font-size:16px ;
    font-family:sans-serif ;
    border-radius: 4px;
    cursor: 'pointer';
    margin: 1px;
  }
  .AvailableSlot:hover{
    background-color: #0cab7d;
    border-radius: 4px;
    cursor: pointer;
    
  }

  .BlockedSlot {
    background-color: red;
    border: 0.5px solid #fff;
    color: #fff !important;
    text-align: center;
    width: 100px;
    flex-grow: 1;
    padding: 10px;
    font-size:16px ;
    font-family:sans-serif ;
    border-radius: 4px;
    cursor: 'pointer';
    margin: 1px;
  }
  .BlockedSlot:hover{
    background-color: #b50404;
    border-radius: 4px;
    cursor: pointer;    
  }