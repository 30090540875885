.transactionModalWidth {
    max-width: 900px !important;
}



.payment-datails {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.payment-datails>div {
    width: 50%;
    margin: 5px 0;
}

.payment-datails-lhs {
    display: flex;
    flex-direction: column;
}

.payment-datails-lhs label,
.payment-datails-rhs label {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
}

.user-datails {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 10px;
}

.user-datails>div {
    width: 50%;
    margin: 5px 0;
}

.user-datails-lhs {
    padding-right: 25px;
}

.table-trans-user-details tr td {
    border: 1px solid #ccc;
    padding: 10px;
}

.trans-client-name {
    display: flex;
    align-items: center;
}

.client-tab-data {
    padding-bottom: 50px;
}

.first-td-width {
    width: 36%;
}

.trans-total-details {
    width: 100%;
}

.trans-total-table-header tr th {
    min-height: 50px;
}

.trans-total-amount {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.trans-total-amount div label {
    margin: 0;
}

.trans-btns {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    margin: 20px 0;
}

.trans-btns>button {
    padding: 0px 15px;
    background-color: transparent;
    border-radius: 4px;
    font-weight: bold;
    border: 0;
    color: #06ADEF;
}

.trans-btns>button:hover {
    background-color: rgb(232, 246, 255);
}

.trans-data-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    margin-bottom: 25px;
}

.trans-data-container>div {
    width: 50%;
}

.trans-data-lhs {
    padding-right: 25px;
}

.trans-data-rhs {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
}

.trans-data-rhs>div>label {
    margin: 0;
}

.no-addons {
    width: 100%;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.trans-data-inner-rhs {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.pay-result {
    font-size: 15px;
    font-weight: bold;
    width: 150px;
    display: flex;
    justify-content: flex-end;
}

.trans-logs-container {
    display: flex;
}

.trans-logs-container>div {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.trans-logs-container-rhs {
    padding-left: 25px;
}

.trans-logs-lhs-text {
    font-size: 15px;
    font-weight: bold;
    width: 35%;
}

.trans-logs-rhs-text {
    font-size: 15px;
}

