.check-activated{
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: green;
    width: 45px;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid green;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px;
    margin-top: 5px;
}
.cross-activate{
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: red;
    width: 45px;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid red;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px;
    margin-top: 5px;
}
