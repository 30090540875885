@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,200;0,300;0,500;1,400&family=Poppins:wght@100;200;300;400;500;600&display=swap');
:root {
    --alumni-family: 'Alumni Sans', sans-serif;
    --poppins-family: 'Poppins', sans-serif;
}
.calender-Container{
    width: 100%;
}
.inner-calender-addons{
    width: 97%;
    padding: 50px 0;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}
.inner-calender-addons .inner-lhs{
    width:50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.inner-calender-addons .inner-rhs{
    width:50%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
.my-court-head{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
}
.my-court-head h2{
    margin: 15px 0 10px 0;
    font-family: var(--poppins-family);
    font-size: 15px;
}
.my-court{
    width: 100%;
    border: 1px solid #e3e3e3;
    border-radius: 2px;
    padding: 0px 20px;
    background-color: white;
}
.myCourt-inner-container{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 13px 0px;
    border-bottom: 1px solid #eeeeee;
}
.myCourt-inner-container:last-child{
    border-bottom:none;
}
.remove-btn{
    border: 0;
    color: white;
    background-color: white;
    border-radius: 5px;
    font-family: var(--poppins-family);
    font-weight: 300;
    justify-content: center;
    align-items: center;
    display: flex;
}
.faTrashAlt{
    color: rgba(180, 54, 54, 0.933);
    font-size: 22px;
}
.myCourt-details{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}
.myCourt-details .court-numbers{
  margin: 0;
  font-size: 12px;
  font-family: var(--poppins-family);
  font-weight: 500;
}
.myCourt-details .court-timing{
  margin: 0;
  font-size: 10px;
  font-family: var(--poppins-family);
  font-weight: 500;
  color: #c0c0c0;
}
.court-timing-amount{
    margin: 0;
    font-size: 15px;
    font-family: var(--poppins-family);
    font-weight: 500;
  }
.addons{
    width: 100%;
    border: 1px solid #e3e3e3;
    border-radius: 2px;
    padding: 10px 20px;
    background-color: white;
}
.inner-addons{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-right: 10px;
}

input[id="add-Checkbox"]{
    background-color: #fff;
    margin: 0;
    font: inherit;
    width: 1em;
    height: 1em;
    border-radius: 1em;
    border: 0;
}

.addons-inner-container{
    width: 90%;
}
.addons-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    justify-content: space-between;
    background-color: #f9f9f9;
    padding: 10px 10px;
    border-radius: 2px;
}
.addons-inner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addons-inner:first-child{
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 7px;
    margin-bottom: 7px;
}
.rents{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.rents h2{
    margin: 0;
    font-size: 14px;
    font-family: var(--poppins-family);
}
.rents small{
    margin: 0;
    font-size: 8px;
    font-family: var(--poppins-family);
    font-weight: 500;
    color: #adadad;

}
.rents h3{
    margin: 0;
    font-size: 14px;
    font-family: var(--poppins-family);
}
.addons-minuts {
    display: flex;
    flex-direction: column;
}
.addons-minuts h4{
    margin: 0;
    font-size: 14px;
    font-family: var(--poppins-family);
}
.select-minuts{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:row;
}
.select-minuts .faMinus{
    color: #ffffff;
    box-shadow: 0px 2px  5px rgb(202, 202, 202);
    border-radius: 50%;
    background-color: rgb(202, 202, 202);
}
.select-minuts .faPlus{
    color: #ffffff;
    border-radius: 50%;
    box-shadow:  0px 2px  5px rgb(202, 202, 202);
    background-color: rgb(202, 202, 202);
}
.select-minuts small{
    padding: 0 7px;
    font-family: var(--poppins-family);
    font-size: 14px;
}
.inner-width-container{
    width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.coupan-input-container{
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    border: 1px solid #e3e3e3;
    padding: 10px;
    border-radius: 2px;
}
.coupan-input-container h5{
    font-size: 10px;
    margin-bottom: 10px;
    font-family: var(--poppins-family);
    font-weight: 500;
}
.coupan-input{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.coupan-input input{
    width: 100%;
    margin-right: 10px;
    border-radius: 2px;
    border: 0;
    background-color: #f9f9f9;
    outline: none;
    padding-left: 12px;
    height: 35px;
}
.coupan-input input::placeholder{
    color: #ccc;
    font-family: var(--poppins-family);
}

.coupan-input input:focus{
    border: 1px solid #FDAE00;
}
.coupan-input button{
    width: 150px;
    background-color: #FDAE00;
    border-radius: 2px;
    border: 0;
    height:35px;
    color: white;
}
.total-amount{
    width: 100%;
    border: 1px solid #e3e3e3;
    border-radius: 2px;
    padding: 5px 20px;
    background-color: white;
}
.total-details{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}
.total-details div{
    font-family: var(--poppins-family);
      font-size: 14px;
      font-weight: 500;
  }
.my-court-head-rhs{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
}
.my-court-head-rhs h2{
    margin: 15px 0 10px 0;
    font-family: var(--poppins-family);
    font-size: 15px;
}
.inner-calender-addons .submit-btns {
    width: 87%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    margin-right: 0;
}
.inner-calender-addons .submit-btns .next{
    background-color: #FDAE00;
    color: white;
    border: 0;
    padding: 2px 15px;
    border-radius: 5px;
    font-family: var(--poppins-family);
    text-decoration: none;
}
.inner-calender-addons .submit-btns .back{
    background-color: #ccc;
    color: white;
    border: 0;
    padding: 2px 15px;
    border-radius: 5px;
    font-family: var(--poppins-family);
    text-decoration: none;
    margin-right: 15px;
}
.inner-width-container .form__form-group-label{
    font-family: var(--poppins-family);
    width:100% ;
    margin-top: 15px;
    color: rgb(114, 114, 114);
}
.total-Amount{
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    width: 97%;
}
.total-details .amount-text{
    color: #adadad;
}
.total-Amount h2{
    font-size: 20px;
    font-family: var(--poppins-family);
}
.payment-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    margin-bottom: 35px;
}
.payment-btn  .payment{
    background-color: #FDAE00;
    color: white;
    font-family: var(--poppins-family);
    border: 0;
    padding: 10px 20px;
    font-size: 13px;
    border-radius: 2px;
    margin-right: 15px;
    font-weight: 500;
}
.payment-btn .cancel{
    background-color: #e2e2e2;
    color: rgb(0, 0, 0);
    font-family: var(--poppins-family);
    border: 0;
    padding: 10px 25px;
    font-size: 13px;
    border-radius: 2px;
    font-weight: 500;
}

@media(max-width:1199px){
    .inner-calender-addons .submit-btns {
        width:100%;
     }
     .inner-calender-addons {
        width: 80%;
        padding: 50px  0;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #ccc;
        margin-bottom: 20px;
    }
}
@media(max-width:1024px){

}
@media(max-width:991px){
    .inner-calender-addons {
        width: 65%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .inner-width-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .inner-calender-addons .inner-lhs, 
    .inner-calender-addons .inner-rhs {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .inner-calender-addons .submit-btns {
       margin-right: 0;
       width: 100%;
    }
    .total-Amount {
        width: 65%;
    }
    .payment-btn  .payment{
        font-size: 14px;
    }
    .payment-btn .cancel{
        font-size: 14px;
    }
    .total-Amount h2{
        font-size: 17px;
    }
}
@media(max-width:768px){
    
    .total-details div {
        font-size: 14px;
    }
   
    .payment-btn  .payment{
        font-size: 12px;
    }
    .payment-btn .cancel{
        font-size: 12px;
    }
    .total-Amount h2{
        font-size: 15px;
    }
    .inner-calender-addons {
        width: 70%;
    }
    .total-Amount {
        width: 70%;
    }
}
@media(max-width:575px){
    .inner-calender-addons .submit-btns {
        margin-right: 0;
        width: 100%;
        padding: 10px;
    }
    .total-details div{
        font-size: 13px;
    }
    .inner-calender-addons .inner-lhs, 
    .inner-calender-addons .inner-rhs {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .total-Amount {
        flex-direction: column;
        align-items: center;
    }
    .payment-btn {
        margin-top: 25px;
    }
    
}
@media(max-width:425px){
    .my-court-head {
        width: 100%;
    }
    .my-court {
        width: 100%;
    }
    .addons {
        width: 100%;
    }
    .inner-width-container {
        width: 100%;
    }
    .inner-calender-addons .submit-btns {
        width: 100%;
    }
    .inner-calender-addons {
        width: 90%;
        padding: 10px;
    }
    .total-details div{
        font-size: 11px;
      }
      .rents h2 {
        font-size: 12px;
    }
    .addons-minuts h4 {
        font-size: 12px;
    }
    .myCourt-details .court-numbers {
        font-size: 12px;
    }
    .myCourt-details .court-timing {
        font-size: 9px;
    }
    .remove-btn {
        font-size: 11px;
    }
    .total-amount {
        padding: 10px;
    }
    .my-court {
        padding: 10px;
    }
    .addons {
        padding: 10px;
    }
}