.active-color-bg {
    width: 110px;
    color: #fff;
    background-color: #26dd7d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    border-radius: 20px;
    font-size: 12px;
    text-transform: capitalize;
}

.canceled-color-bg {
    width: 110px;
    color: #fff;
    background-color: #ff9292;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: italic;
    border-radius: 20px;
    font-size: 12px;
    text-transform: capitalize;
}

.user-email,
.user-phone {
    color: #06adef;
}

.user-email:hover,
.user-phone:hover {
    color: #0080b3;
    text-decoration: underline;
}

.Booking-details-td-right-space {
    padding-right: 10px;
    font-size: 13px;
}

.Booking-details-td-left-space {
    margin-left: 10px;
    background: transparent;
}

.hover-effect .table .resizable-table tr:hover .Booking-details-td-left-space {
    background-color: #E6F7FD;
}
.hover-effect .table .resizable-table tr:hover .react-table__highlighter, .kids-summer-camp-id {
    background-color: #E6F7FD;
}
.hover-effect .table .resizable-table tr:hover .kids-summer-camp-id {
    background-color: #E6F7FD;
}
.hover-effect .table .resizable-table tr:hover .Booking-details-td-right-space {
    background-color: #E6F7FD;
}

.hover-effect .table .resizable-table tr:hover .booking-action-column {
    background-color: #E6F7FD;
}

.hover-effect .table .resizable-table tr:hover .model-container {
    background-color: #E6F7FD;
}

.hover-effect .table .resizable-table tr:hover .right-space {
    background-color: #E6F7FD;
}

.theme-light .table__search .table__search-input {
    color: #646777;
    background: white;
    margin-top: 15px;
}

.pagination-hide nav .pagination {
    display: none;
}

.filter-search {
    display: flex;
    margin-top: 15px;
}

.filter-search>.filter-search-inner>input {
    height: 35px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
    padding-left: 8px;
    outline: #80bdff;
}

.filter-search>input:focus {
    border-color: #79d7fc;
    box-shadow: 0 0 0 3px rgb(6 173 239 / 25%);
}

.hide-filtername>div>.table__search>.table__search {
    display: none;
}

.custom-pagination {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
}

.filter-search-btn {
    background-color: #06adef;
    outline: none;
    border: 0;
    border-radius: 4px;
    color: #fff;
    height: 35px;
    padding: 5px 15px;
}

#select-pagination {
    border: 1px solid #dee2e6;
    margin-left: 10px;
    width: 70px;
    border-radius: 5px;
    outline: none;
    color: #71757d;
    padding: 0 10px;
    height: 37px;
}

.records-per-page {
    color: #71757d;
}

#select-pagination:focus {
    border-color: #79d7fc;
    box-shadow: 0 0 0 3px rgb(6 173 239 / 25%);
}

.filter-search>.filter-search-inner>select {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
    padding-left: 8px;
    outline: #80bdff;
}

.filter-search-inner {
    display: flex;
    flex-direction: column;
}

.filter-search-inner>.filter-search-error {
    text-transform: capitalize;
    color: rgb(184, 16, 16);
}

.package-fields {
    display: flex;
}

.package-fields>input {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 7px;
    padding-left: 8px;
    outline: #80bdff;
}

.package-fields>div>input {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 7px;
    padding-left: 8px;
    outline: #80bdff;
}

.filter-fields {
    display: flex;
    justify-content: space-between;
}

.filter-fields>input {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 7px;
    padding-left: 8px;
    outline: #80bdff;
}

.filter-fields>div>input {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 7px;
    padding-left: 8px;
    outline: #80bdff;
}

.filter-client-email-select-option {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 7px;
    padding-left: 8px;
    outline: #80bdff;
}

.filter-fields>.filter-code {
    width: 90px;
}

.fill-code {
    width: 110px;
}

.filter-fields .filter-created {
    width: 100%;
}

.filter-fields .filter-date {
    width: 100%;
    display: flex;
}

.filter-fields>.filter-service {
    width: 10%;
    margin-right: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 34px;
}

.fill-service {
    width: 15% !important;
}

.filter-fields>.filter-service-provider {
    width: 10%;
    margin-right: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 34px;
}

.fill-provider {
    width: 13% !important;
}

.filter-fields>.filter-client {
    width: 19%;
}

.filter-fields>.filter-payment-status {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 7px;
    padding-left: 8px;
    outline: #80bdff;
    width: 11%;
}

.filter-fields>.btns-container>.clear-btn {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #fdae00;
    margin-right: 2px;
    outline: #fdae00;
    width: 50%;
    color: #fdae00;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    cursor: pointer;
}

.filter-fields>.btns-container>.apply-btn {
    height: 34px;
    border-radius: 4px;
    background-color: #fdae00;
    border: 1px solid #fdae00;
    margin-right: 2px;
    outline: #80bdff;
    width: 50%;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    cursor: pointer;
}

.btns-container {
    display: flex;
    justify-content: center;
    width: 10%;
}

/* .center-spinner {
    position: absolute;
    background-color: black;
    width: 96%;
    height: 94%;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    opacity: 0.1;
} */

.center-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100000;
}

.admin-bg {
    padding: 1px 8px;
    background-color: #fcbc2d;
    margin-top: 4px;
    color: #fff;
    border-radius: 10px;
    font-style: italic;
    text-transform: capitalize;
}

.app-bg {
    padding: 1px 8px;
    background-color: #eaaa1f;
    margin-top: 4px;
    color: #fff;
    font-style: italic;
    border-radius: 10px;
    text-transform: capitalize;
}

.dowloadbtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}

.download-booking-table-xls-button {
    margin-left: 10px;
    background-color: #fdae00;
    color: white;
    padding: 7px 10px;
    outline: none;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
}

.download-booking-table-xls-button-all {
    background-color: #fdae00;
    color: white;
    padding: 7px 10px;
    outline: none;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
}

.error-msg {
    color: #b60606;
    font-size: 10px;
}

.spinner-patient__container {
    display: flex;
    align-items: center;
}

.spinner-patient__title {
    align-items: center;
    display: flex;
    margin: 10px 30px;
}

.custom_doc_text_fazaa {
    color: white;
    background: #2400ff;
    width: 70px;
    padding: 4px 6px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
}

.custom_doc_text_esaad {
    color: white;
    background: #ff9200;
    width: 70px;
    padding: 4px 6px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
}

.custom_slot_report {
    width: 100px !important;
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding: 5px;
}