.tabs.tabs--justify .nav-tabs {
    width: 300px;
}

.all-tabs{
    padding-left: 0 !important;
}

.nav-item .nav-link {
    color: #7e7e7e;
    font-size: 16px;
    font-family: 'Trebuchet MS', sans-serif;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #000000;
}

.tabs.tabs--bordered-bottom .nav-tabs li .nav-link.active,
.tabs.tabs--bordered-bottom .nav-tabs li .nav-link.active:focus,
.tabs.tabs--bordered-bottom .nav-tabs li .nav-link.active:hover {
    border-bottom: 4px solid #FFD943;
}

.time-date-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #06ADEF;
    border-radius: 5px;
    width: 92%;
    margin: 10px 0;
}

.calender-time-date {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.calender-time-date>h4 {
    color: #FFF;
    font-size: 15px;
    font-family: sans-serif;
}

.calender-icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cal-icon {
    color: white;
    margin-right: 10px;
    font-size: 20px;
}

.calender-icon>h4 {
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    font-family: sans-serif;
}

.cal-time {
    font-size: 16px !important;
    font-weight: 400 !important;
    font-family: sans-serif;
}

.coupan-container {
    width: 92%;
    height: 32px;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.coupan-container h4 {
    font-size: 16px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

.show-all-edits-left {
    padding: 4px 10px 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.show-all-edits-left .edit-line {
    width: 1px;
    height: 25px;
    background-color: #ccc;
    margin-top: 5px;
}

.show-all-edits-right h5 {
    font-size: 13px;
    color: #2c3547;
}

.edit-icon {
    color: #1bb4f0;
    font-size: 20px;
}

.active-tab {
    border-bottom: 4px solid orange !important;
  }