:root {
    --rmdp-primary-orange: #FDAE00;
    --rmdp-secondary-orange: #eac26b;
    --rmdp-shadow-orange: #FDAE00;
    --rmdp-today-orange: #FDAE00;
    --rmdp-hover-orange: #f3ce3e;
    --rmdp-deselect-orange: #FDAE00;
  }
  
.rmdp-wrapper {
    border: 1px solid var(--rmdp-secondary-orange);
    box-shadow: 0 0 5px var(--rmdp-secondary-orange);
  }
  
.rmdp-panel-body li {
    background-color: var(--rmdp-primary-orange);
    box-shadow: 0 0 2px var(--rmdp-secondary-orange);
  }
  
.rmdp-week-day {
    color: var(--rmdp-primary-orange);
  }
  
.rmdp-day.rmdp-deactive {
    color: var(--rmdp-secondary-orange);
  }
  
.rmdp-range {
    background-color: var(--rmdp-primary-orange);
    box-shadow: 0 0 3px var(--rmdp-shadow-orange);
  }
  
.rmdp-arrow {
    border: solid var(--rmdp-primary-orange);
    border-width: 0 2px 2px 0;
  }
  
.rmdp-arrow-container:hover {
    background-color: var(--rmdp-primary-orange);
    box-shadow: 0 0 3px var(--rmdp-secondary-orange);
  }
  
.rmdp-panel-body::-webkit-scrollbar-thumb {
    background: var(--rmdp-primary-orange);
  }
  
.rmdp-day.rmdp-today span {
    background-color: var(--rmdp-today-orange);
  }
  
.rmdp-rtl .rmdp-panel {
    border-left: unset;
    border-right: 1px solid var(--rmdp-secondary-orange);
  }
  
.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: var(--rmdp-primary-orange);
    box-shadow: 0 0 3px var(--rmdp-shadow-orange);
  }
  
.rmdp-day:not(.rmdp-day-hidden) span:hover {
    background-color: var(--rmdp-hover-orange) !important;
  }
  
.b-deselect {
    color: var(--rmdp-deselect-orange);
    background-color: white;
  }
  
.rmdp-action-button {
    color: var(--rmdp-primary-orange);
  }
  
.rmdp-button:not(.rmdp-action-button) {
    background-color: var(--rmdp-primary-orange);
  }
  
.rmdp-button:not(.rmdp-action-button):hover {
    background-color: var(--rmdp-deselect-orange);
  }
  