@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,200;0,300;0,500;1,400&family=Poppins:wght@100;200;300;400;500;600&display=swap');
:root{
    --alumni-family: 'Alumni Sans', sans-serif;
    --poppins-family: 'Poppins', sans-serif;
}
.banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 100px;
}

.background-overlay {
    width: 100%;
    background-color: #000;
    opacity: 0.66;
    position: absolute;
    top: 0px;
    height: 100%;
}

.padel-title {
    position: absolute;
    margin-top: 100px;
}

.padel-title h1 {
    color: #fff;
    font-family: var(--alumni-family);
    font-size: 90px;
    letter-spacing: 2px;
}

.booking-containers {
    width: 97%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.8rem;
    margin-top: 20px;
}
.booking-containers .courts {
    /* border: 1px solid #D9D9D9; */
    box-shadow: 0 0 10px #e0e0e0;
    height: auto;
    border-radius: 5px;
    overflow: hidden;
}

.padelImg{
    width: 100%;
    height: 200px;
}
.court-details-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
}
.court-details {
    width: 90%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    align-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
    position: relative;
    height: 300px;
}

.court-inner {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.court-details h4 {
    font-family: var(--poppins-family);
    font-size: 17px;
    margin: 5px 0;
    color: #232240;
    text-align: left;
}
.court-count {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.court-count h5 {
    margin: 0;
    font-family: var(--poppins-family);
    font-weight: 400;
    font-size: 15px;
    color: #232240;
}

.court-icons {
    display: flex;
}

.faPhone {
    color: #0000FF;
    margin-right: 20px;
}

.faPaperPlane {
    color: #FDAF2B;
    margin-right: 15px;
}



.court-time {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.court-time-second {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.court-time h5 {
    margin: 0px 5px;
    color: #232240;
    font-size: 15px;
    font-family: var(--poppins-family);
    font-weight: 400;
}

.court-time-second h5 {
    margin: 0px 5px;
    color: #232240;
    font-size: 16px;
    font-family: var(--poppins-family);
    font-weight: 400;
}

.faClock {
    color: #232240;
}

.court-has {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 20px 0px 10px 0px;
    flex-wrap: wrap;
}

.court-has-time {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 25px 0px 10px 0px;
}

.court-has-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 20px 0px 10px 0px;
}

.court-has-time small {
    padding: 5px 15px;
    background-color: #F5F5F5;
    border-radius: 4px;
    margin-right: 10px;
    font-family: var(--poppins-family);
}

.court-has small {
    padding: 3px 5px;
    background-color: #F5F5F5;
    border-radius: 4px;
    margin-right: 5px;
    font-family: var(--poppins-family);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
    font-size: 11px;
}

.court-has-title small {
    padding: 5px 15px;
    background-color: #F5F5F5;
    border-radius: 4px;
    margin-right: 10px;
    font-family: var(--poppins-family);
}

.book-btn {
    position: absolute;
    bottom: 10px;
    width: 100%;
    background-color: #FDAE00;
    color: #fff;
    border: 0;
    border-radius: 4px;
    height: 40px;
    font-family: var(--poppins-family);
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
}

.book-btn:hover {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #FDAE00;
    transition: all .3s;
}

.book-btn h5 {
    color: #fff;
    font-family: var(--poppins-family);
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
}

.book-btn:hover h5 {
    color: #FDAE00;
    transition: all .3s;
}

.justpadel-buttons{
    height: 35px;
    background-color: #FDAE00;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    border-radius: 4px;
    font-size: 15px;
    font-family: sans-serif;
    cursor: pointer;
}
.justpadel-buttons-save{
    height: 35px;
    background-color: #FDAE00;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;
    border: none;
}
.justpadel-buttons-cancel{
    height: 35px;
    background-color: #cccccc;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    border-radius: 4px;
    font-size: 15px;
    font-family: sans-serif;
    cursor: pointer;
    border: none;
}
.go-to-cart-btn-container{
    display: flex;
    justify-content: flex-end;
    width: 97%;
    margin-left: 1.5%;
}

@media(min-width: 1680px){
    .booking-containers {
        grid-gap: 10rem;
    }
}
@media(max-width: 991px){
    .booking-containers {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media(max-width: 575px){
    .booking-containers {
        grid-template-columns: repeat(1, 1fr);
    }

}
@media(max-width: 425px){
    .padelImg {
        height: 190px;
    }

}
@media(max-width: 320px){
    .padelImg {
        height: 160px;
    }
}
