.rechargePack {
    color: #65c930;
    font-size: 16px;
    font-weight: bold;
}

.deductPack {
    color: #c93630;
    font-size: 16px;
    font-weight: bold;
}

.payment_status-completed {
    color: #ffffff;
    background-color: #26dd7d;
    border-radius: 15px;
    font-size: 12px;
    font-style: italic;
    width: 100px;
    display: flex;
    line-height: 1.3;
    justify-content: center;
    align-items: center;
}

.payment_status-incompleted {
    color: #ffffff;
    background-color: #ff9292;
    border-radius: 15px;
    font-size: 12px;
    font-style: italic;
    width: 100px;
    display: flex;
    line-height: 1.3;
    justify-content: center;
    align-items: center;
}

.purchase-using {
    color: #ffffff;
    background-color: #fcbc2d;
    border-radius: 15px;
    font-size: 12px;
    font-style: italic;
    width: 70px;
    display: flex;
    line-height: 1.2;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
}

.cal-top-submit-btn {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}