// .card {
//   width: 100%;
//   height: 100%;
//   border: none;
//   background-color: transparent;

//   &.card--not-full-height {
//     height: auto;
//   }
// }

// .card-body {
//   padding: 30px;
//   height: 100%;
//   border-radius: 10px;

//   @include themify($themes) {
//     background-color: themed('colorBackground');
//   }
// }

// .card__title {
//   margin-bottom: 20px;
//   text-transform: uppercase;
//   position: relative;

//   &:not(:first-child) {
//     margin-top: 40px;
//   }

//   .subhead {
//     text-transform: none;
//     font-size: 12px;
//     line-height: 18px;
//     opacity: 0.7;
//     margin-top: 3px;
//   }

//   * {
//     margin-bottom: 0;
//   }

//   h5 {
//     font-size: 12px;
//     font-family: Verdana, sans-serif;
//   }
// }

// .squared-corner-theme {

//   .card-body {
//     border-radius: 0;
//   }
// }

// .blocks-with-shadow-theme {

//   .card-body {
//     box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
//   }
// }

.card {
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto; // Ensures this modifier works correctly.
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 10px;

  @include themify($themes) {
    background-color: themed('colorBackground'); // Ensure that themify works correctly.
  }
}

.card__title {
  margin-bottom: 20px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0; // Resets margin for all child elements
  }

  h5 {
    font-size: 12px;
    font-family: Verdana, sans-serif;
  }
}

// Theme for squared corners
.squared-corner-theme {
  .card-body {
    border-radius: 0; // Removes border radius for this theme
  }
}

// Theme for blocks with shadow
.blocks-with-shadow-theme {
  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06); // Applies shadow effect
  }
}
