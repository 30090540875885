.table .react-table__column-header.sortable svg {
    position: absolute;
    right: 20px;
    top: 12px;
}

.tabs__wrap {
    padding-bottom: 20px;
}

.userEmail {
    color: rgb(19, 163, 230);
    font-size: 12px;
}

.userPhone {
    color: rgb(19, 163, 230);
}

.userPhone:hover {
    text-decoration: underline;
    color: rgb(14, 112, 158);
}

.userEmail:hover {
    text-decoration: underline;
    color: rgb(14, 112, 158);
}

.bookingId {
    font-weight: 600;
}

.amountPaid {
    font-weight: 600;
}

.amountPaidPayform {
    font-weight: 300;
}

.amountPaidPaidHalf {
    font-size: 11px;
    background-color: #26dd7d;
    color: #fff !important;
    line-height: normal !important;
    padding: 2px 10px;
    width: 75px;
    border-radius: 20px;
    font-style: italic;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.amountPaidPaid {
    font-size: 11px;
    background-color: #26dd7d;
    color: #fff !important;
    line-height: normal !important;
    padding: 2px 10px;
    width: 45px;
    border-radius: 20px;
    font-style: italic;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.amountPaidCoupanCode {
    font-size: 11px;
    background-color: #26dd7d;
    color: #fff !important;
    line-height: normal !important;
    padding: 2px 10px;
    width: 70px;
    margin-top: 5px;
    height: auto !important;
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.amountStatusCompleted {
    font-size: 11px;
    background-color: #26dd7d;
    color: #fff !important;
    line-height: 1 !important;
    padding: 2px 10px;
    width: 80px;
    height: 18px;
    border-radius: 20px;
    font-style: italic;
}

.amountStatusCancelled {
    font-size: 11px;
    background-color: #ff9292;
    color: #fff !important;
    line-height: 1 !important;
    padding: 2px 10px;
    width: 80px;
    height: 18px;
    border-radius: 20px;
    font-style: italic;
}

.text {
    display: inline;
    width: 100%;
}

.read-or-hide {
    color: rgb(192, 192, 192);
    cursor: pointer;
}

.right-space {
    padding-right: 10px;
}

.trans-left-space {
    padding-left: 10px;
}

.second-child-space .table thead th:nth-child(2) {
    padding-left: 10px;
}

.description.offer-list h4 span,
.description.offer-list p {
    font-size: 16px !important;
    color: rgb(102, 102, 102) !important;
}

.get-page-right {
    width: 96%;
}

.table .table--bordered tr {
    display: flex;
    align-items: center;
}

.action-btn {
    padding: 5px 15px !important;
}

.action-dropdown {
    background-color: transparent;
}

.ceated-date {
    font-size: 13px;
}

.payment-status {
    font-size: 13px;
}

.item-data {
    font-size: 12px;
}

.dropdown {
    width: 100px;
    position: relative;
}

.dropdown>.dropdown-content {
    position: absolute;
    z-index: 10;
    top: 110%;
    right: 0;
    width: 130px;
    padding: 10px;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.dropdown>.dropdown-content>.dropdown-items {
    padding: 3px 5px;
    cursor: pointer;
    transition: all 0.2s;
}

.dropdown>.dropdown-content>.dropdown-items:hover {
    background-color: #f4f4f4;
}

.download-table-xls-button {
    background-color: #FDAE00;
    color: white;
    padding: 7px 10px;
    outline: none;
    border: 0;
    border-radius: 4px;
    position: absolute;
    right: 5px;
    top: 124px;
    cursor: pointer;
}

.downloadpdf {
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #06ADEF;
    color: #06ADEF;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-top: -10px;
    width: 114px;
    margin-right: 2px;
}

.table-id-desabled .table .resizable-table th:first-child,
.table-id-desabled .table .resizable-table td:first-child {
    display: none;
}

.hover-effect {
    width: 100%;
    overflow: hidden;
}

.hover-effect .table .resizable-table tr:nth-child(odd) {
    background-color: #FCFCFC
}

.hover-effect .table .resizable-table tr:hover {
    background-color: #E6F7FD;
}

.table-id-width .table .resizable-table th,
.table-id-width .table .resizable-table td {
    min-width: 75px !important;
}

.search-input-update .table__search .table__search-input {
    height: 35px;
}

.trans-buttons {
    display: flex;
    align-items: center;
}

.pagination .pagination .pagination {
    margin-bottom: 20px;
}

.paid-book__using {
    display: flex;
}

.booked-using {
    font-size: 12px;
    background-color: #FDAE00;
    color: #fff !important;
    width: auto;
    padding: 2px 10px;
    border-radius: 20px;
    font-style: italic;
    display: flex;
    justify-content: center;
    line-height: normal !important;
    margin-left: 5px;
}

.btn.btn-primary:before {
    background-color: #FDAE00;
}


/* ----------------filter inputs css -----------------------------------*/

.booking-dates {
    display: flex;
    flex-direction: column;
}

.payment-dates {
    display: flex;
    flex-direction: column;
}

.email-name-feilds {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 21px;
}

.Padel-court-feilds {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 21px;
}

.filter-Phone-number {
    width: 15%;
    margin-top: 21px;
}

.filter-payment-date {
    margin-bottom: 7px;
}

.filter-booking-date {
    margin-bottom: 7px;
}

.booking-date {
    text-transform: capitalize;
    font-size: 13px;
}

.search-booking-id {
    margin-top: 21px;
}

.filter-name {
    margin-bottom: 7px;
}

.filter-paid-with {
    width: 12%;
    margin-right: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 34px;
}

.filter-payment_status {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 7px;
    padding-left: 8px;
    outline: #80bdff;
    width: 12%;
}

.filter-client-name {
    margin-bottom: 7px;
}

.filter-fields>.btn-container>.clear-btn-trans {
    height: 34px;
    border-radius: 4px;
    border: 1px solid #FDAE00;
    margin-right: 2px;
    outline: #FDAE00;
    width: 100%;
    color: #FDAE00;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    cursor: pointer;
}

.filter-fields>.btn-container>.apply-btn-trans {
    height: 34px;
    border-radius: 4px;
    background-color: #FDAE00;
    border: 1px solid #FDAE00;
    margin-right: 2px;
    outline: #80bdff;
    width: 100%;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    cursor: pointer;
    margin-top: 7px;
}

.btn-container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 7%;
    margin-top: 21px;
}

.hide-table-costomizer .react-table__customizer {
    display: none;
}

.table-top-space {
    margin-top: 60px;
}

.filter-fields>.trans-filter-service-provider {
    margin-right: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 34px;
    margin-top: 7px;
}


.add-packages {
    background-color: #FDAE00 !important;
    border: none;
}

.add-packages {
    background-color: #FDAE00 !important;
    border: none;
}

.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:active:focus {
    background-color: #FDAE00 !important;
}

.bg-warning {
    border: none;
}

.tfoot{
    display: none !important;
}