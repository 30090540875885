.form {
    display: flex;
    flex-wrap: wrap;
    input,
    textarea {
        width: 100%;
        padding: 5px 10px;
        font-size: 12px;
        height: 32px;
        transition: border 0.3s;
        background: transparent;
        &::-webkit-input-placeholder {
            color: $color-additional;
        }
        &::-moz-placeholder {
            color: $color-additional;
        }
        /* Firefox 19+ */
        &:-moz-placeholder {
            color: $color-additional;
        }
        /* Firefox 18- */
        &:-ms-input-placeholder {
            color: $color-additional;
        }
        @include themify($themes) {
            border: 1px solid themed('colorFieldsBorder');
            color: themed('colorText');
        }
        &[disabled] {
            @include themify($themes) {
                background: themed('colorBackgroundBody');
            }
            &:focus,
            &:active {
                @include themify($themes) {
                    border-color: themed('colorBorder');
                }
            }
        }
        &:focus,
        &:active {
            outline: none;
            border-color: orange;
        }
    }
    textarea {
        min-height: 85px;
    }
    &.form--horizontal {
        .form__form-group {
            display: flex;
            flex-wrap: wrap;
        }
        .form__form-group-field {
            width: calc(100% - 80px);
            padding-left: 10px;
            margin-left: 80px;
        }
        .form__form-group-label {
            width: 80px;
            max-height: 32px;
            line-height: 18px;
            margin: auto 0;
            &+.form__form-group-field {
                margin-left: 0;
            }
        }
        .form__form-group-description {
            margin-left: 90px;
        }
        .form__button-toolbar {
            margin-left: 0;
        }
        .form__form-group-input-wrap--error-above {
            margin-bottom: 15px;
            .form__form-group-error {
                top: -28px;
            }
        }
        @media screen and (min-width: 480px) {
            .form__form-group-label {
                width: 120px;
            }
            .form__form-group-field {
                width: calc(100% - 120px);
                margin-left: 120px;
                padding-left: 20px;
            }
            .form__form-group-description,
            .form__button-toolbar {
                margin-left: 140px;
            }
        }
    }
    &.form--preview {
        display: flex;
        &>div:nth-child(2) {
            margin-right: 50px;
        }
        .form__form-group {
            margin-bottom: 10px;
            width: auto;
            min-height: 18px;
        }
        .form__select-color {
            display: flex;
            flex-wrap: wrap;
            max-width: 84px;
            margin-right: 40px;
        }
        p {
            margin-bottom: 10px;
        }
    }
    &.form--justify {
        display: flex;
        flex-wrap: wrap;
        .form__form-group {
            width: 33.3333%;
        }
        .form__button-toolbar {
            width: 100%;
        }
    }
    .form__form-group-select {
        width: 100%;
        height: 32px;
        font-size: 12px;
        .Select-control {
            height: 32px;
            border-radius: 0;
            transition: all 0.3s;
            background: transparent;
            @include themify($themes) {
                border: 1px solid themed('colorFieldsBorder');
            }
        }
        .Select-placeholder,
        .Select-input {
            height: 30px;
        }
        .Select-input {
            width: 100%;
        }
        input {
            width: 100% !important;
            border: none;
            padding: 0;
        }
        .Select-multi-value-wrapper {
            .Select-input {
                width: inherit;
            }
            .Select-value {
                background: transparent;
                border-color: $color-blue;
            }
            .Select-value-label {
                @include themify($themes) {
                    color: themed('colorText') !important;
                }
            }
            .Select-value-icon {
                border-color: $color-blue;
            }
        }
        .Select-menu-outer {
            top: calc(100% + 1px);
            border-radius: 0;
            box-shadow: none;
            font-size: 12px;
            animation: open 0.3s ease-in-out;
            overflow: hidden;
            @include themify($themes) {
                border: 1px solid themed('colorBorder');
                background: themed('colorBackground');
            }
        }
        @keyframes open {
            0% {
                max-height: 0
            }
            100% {
                max-height: 200px
            }
        }
        .Select-option {
            transition: all 0.3s;
            border-radius: 0;
            display: flex;
            @include themify($themes) {
                background: themed('colorBackground');
                color: themed('colorText');
            }
            &.is-focused {
                @include themify($themes) {
                    background: themed('colorHover');
                }
            }
        }
        &.is-focused,
        &.is-focused:not(.is-open) {
            .Select-control {
                border-color: $color-accent;
                box-shadow: none;
                background: transparent;
            }
        }
        .form__form-group-select-color {
            display: block;
            border-radius: 50%;
            height: 10px;
            width: 10px;
            margin: auto 0 auto 5px;
        }
    }
}

.form__half {
    width: calc(50% - 15px);
    height: 100%;
    &:first-child {
        margin-right: 30px;
    }
    .form__button-toolbar {
        float: right;
    }
    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0;
    }
}

.form__form-group {
    width: 100%;
    position: relative;
}

.form__form-group--address {
    input:last-child {
        margin-left: 15px;
        width: 70%;
    }
}

.form__form-group-field {
    width: 100%;
    display: flex;
    margin: auto;
}

.form__form-group-label {
    margin-bottom: 4px;
    display: inline-block;
    @include themify($themes) {
        color: themed('colorText');
    }
    span {
        color: #dddddd;
    }
}

.form__form-group-button {
    padding: 6px;
    height: 32px;
    cursor: pointer;
    transition: all 0.3s;
    @include themify($themes) {
        background: themed('colorFieldsBorder');
        border: 1px solid themed('colorFieldsBorder');
    }
    svg {
        fill: $color-additional;
        width: 18px;
        height: 18px;
        transition: all 0.3s;
    }
    &.active {
        background: $color-accent;
        border: 1px solid $color-accent;
        svg {
            fill: #ffffff;
        }
    }
}

.form__form-group-file {
    label {
        border-radius: 2px;
        line-height: 18px;
        font-size: 12px;
        padding: 4px 20px;
        cursor: pointer;
        transition: all 0.3s;
        text-align: center;
        @include themify($themes) {
            border: 1px solid themed('colorFieldsBorder');
            color: themed('colorText');
        }
        &:hover {
            @include themify($themes) {
                background: themed('colorFieldsBorder');
            }
        }
    }
    span {
        padding-left: 10px;
    }
    input {
        display: none;
    }
}

.form__form-group-icon {
    padding: 6px;
    height: 32px;
    @include themify($themes) {
        background: themed('colorFieldsBorder');
        border: 1px solid themed('colorFieldsBorder');
    }
    svg {
        fill: $color-additional;
        width: 18px;
        height: 18px;
        transition: all 0.3s;
    }
}

.form__form-group-description {
    font-size: 10px;
    color: $color-additional;
    line-height: 13px;
    margin-top: 2px;
}

.form__button-toolbar {
    margin-top: 10px;
}

.form__form-group-input-wrap {
    width: 100%;
}

.form__form-group-error {
    font-size: 10px;
    line-height: 13px;
    color: #ad4444;
    margin-bottom: -5px;
    display: block;
    margin-top: 5px;
}

.form__form-group-input-wrap--error-above {
    .form__form-group-error {
        position: absolute;
        margin: 0;
        right: 0;
        top: 0;
        padding: 5px 10px;
        background: #ffbcbc;
        border-radius: 3px;
        &:after {
            content: '';
            position: absolute;
            right: 10px;
            bottom: -8px;
            border: 4px solid transparent;
            border-top: 4px solid #ffbcbc;
        }
    }
}

.form__form-group-date-cvc {
    display: flex;
    width: 100%;
    .form__form-group-date {
        width: 100%;
        margin-right: 20px;
    }
    .form__form-group-cvc {
        max-width: 280px;
        width: 100%;
    }
    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        .form__form-group-date {
            margin-right: 0;
        }
        .form__form-group-cvc {
            max-width: 100%;
        }
    }
}

.form__form-group-id-category {
    width: 100%;
    display: flex;
    .form__form-group-id {
        min-width: 100px;
        width: 40%;
        margin-right: 20px;
    }
    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        .form__form-group-id {
            margin-right: 0;
            width: 100%;
        }
    }
}

.form__form-group-price-discount {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    &>div {
        margin-bottom: 0;
    }
    .form__form-group-price {
        margin-right: 20px;
    }
    @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        .form__form-group-price {
            margin-right: 0;
        }
    }
}

.select {
    border: 1px solid #9c9c9c;
    color: #646777;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    outline: orange;
    // width: 100%;
    border-radius: 5px;
    font-family: sans-serif;
}

.select:active {
    outline: none;
    border-color: orange;
}

.select:focus {
    outline: none;
    border-color: orange;
}

.float_right {
    float: right;
}