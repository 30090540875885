.booking-id{
    font-size: 15px;
     font-weight: "Bold";
     font-weight: 600;
}
.clientBackground{
    font-size: 17px;
    display: flex;
    gap: 45px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 12px;
    width: 100%;
    justify-content: flex-start;
}
.clientlabel{
    font-size: 17px;
    font-weight: "Bold";
    font-weight: 600;
    margin-top: 5px;
    margin-left: 12px;
}
.emailspan{
    font-size: 17px;
     margin-top: 5px;
      margin-right: 20px; 
}
.phonediv{
    font-size: 17px;
    display: flex;
    gap: 34px;
    background-color: #f5f5f5;
    border-radius: 4px;
    width: 100%;
    justify-content: flex-start;
}
.phonelabel{
    font-size: 17px;
    font-weight: "Bold";
    font-weight: 600;
    margin-top: 5px;
    margin-left: 10px;
}
.categorycol{
    margin-top: 10px;
    border-radius: 8px;
    padding: 10px;
    background-color: #f5f5f5;
    height: 19%;
}
.categorydiv{
    display: flex;
    margin-bottom: 12;
    height: 16%;
    justify-content: space-between;
}
.categorylabel{
    font-size: 15px;
    margin-top: 4px;
    margin-left: 10px;
}
.courtdiv{
    display: flex;
    background-color: #d43a33;
    border-radius: 4px;
    height: 30%;
    margin-left: 10px;
}
.courtlabel{
    font-size: 15px;
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 7px;
    color: white;
}
.totalpricelabel{
    font-size: 15px;
    margin-top: 5px;
    margin-left: 10px;
}
.AEDfirstdiv{
    background-color: #bfc2c7;
    border-radius: 4px;
    margin-top: 6px;
    height: 70%;
}
.AEDfirstlable{
    font-size: 14px;
    padding: 4px 48px 0px 48px;
}
.minutesdiv{
    background-color: #484f59;
    border-radius: 4px;
    margin-top: 6px;
    height: 67%;
}
.minuteslabel{
    font-size: 16px;
    color: white;
     padding: 3px 10px 0px 10px;
}
.percentagediv{
    background-color: #484f59;
    border-radius: 4px;
    margin-top: 6px;
    height: 70%;
}
.percentagelabel{
    font-size: 16px;
    color: white;
    padding: 3px 12px 0px 12px;
}